import ContainerX from "../../../components/general/ContainerX";
import CardNeu from "../../../components/Card/Card";
import { ColumnFlex } from "../../../components/LayoutX/Flex/ColumnFlex";
import { Fab } from "ui-neumorphism";
import PropertyX_logo from "../../../assets/images/property-X.png";
import { RowFlex } from "../../../components/LayoutX/Flex/RowFlex";
import { useParams } from "react-router-dom";
import TextFieldNeu from "../../../components/TextField/TextFieldNeu";
import { useEffect, useState } from "react";
import Spinner from "../../../components/Spinner/Spinner";
import { useSnackbar } from "react-simple-snackbar";
import {
  useCheckAccountCodeValidLazyQuery,
  useForgotPasswordMutation,
} from "../../../generated/graphql";
import { cardChangeColor } from "../../../containers/context/CardContext";

const ForgotPassword = () => {
  const [emailValue, setEmailValue] = useState("");
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const { accountCode = "" } = useParams();
  const [
    getAccountCode,
    {
      data: isValidAccountCodeResponse,
      loading: getAccCodeLoading,
      error: getAccCodeError,
    },
  ] = useCheckAccountCodeValidLazyQuery({});
  const { cardColor } = cardChangeColor();

  useEffect(() => {
    const sendRequest = async () => {
      await getAccountCode({
        variables: {
          accountCode: accountCode || "",
        },
      });
    };

    sendRequest();
  }, [accountCode]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value);
  };

  const [
    useForgotPasswordRequest,
    { data, loading, error: forgotPasswordError },
  ] = useForgotPasswordMutation({
    variables: {
      Email: emailValue || "",
      AccountCode: accountCode || "",
    },
  });

  const handleForgotPassword = () => {
    useForgotPasswordRequest();
  };

  return (
    <div className="Auth-Container">
      <ColumnFlex gap="30px" crossAxisAlignment="center">
        <div style={{ width: "100%" }}>
          <CardNeu elavation={2} style={{ background: cardColor }}>
            <ContainerX
              padding={{
                symmetric: { vertical: "20px", horizontal: "40px" },
              }}
              alignment="center"
            >
              <ColumnFlex crossAxisAlignment="center" gap="20px">
                <ColumnFlex crossAxisAlignment="center" gap="0px">
                  <img
                    src={
                      isValidAccountCodeResponse?.checkAccountCodeValid
                        ?.purchaserAppCss?.logoUrl || PropertyX_logo
                    }
                    className="company-logo"
                  />
                  <span
                    className="color-orange text-with-shadow fs-14"
                    style={{
                      width: "100%",
                      textAlign: "end",
                      paddingRight: "10%",
                    }}
                  >
                    {isValidAccountCodeResponse?.checkAccountCodeValid
                      ?.purchaserAppCss?.showPurchaserAppWord
                      ? "Purchaser App"
                      : null}
                  </span>
                </ColumnFlex>
                <span className="fs-20 fw-700 text-with-shadow color-text">
                  Forgot Password
                </span>
                <span className="fs-14 fw-300">
                  Enter your email and we'll send you the password reset link.{" "}
                </span>
                <TextFieldNeu
                  placeholder="Email"
                  showWarning
                  type="email"
                  onInput={handleEmailChange}
                  value={emailValue}
                />
              </ColumnFlex>
            </ContainerX>
          </CardNeu>
        </div>
        <Fab
          className={"fullwidth-with-child"}
          disabled={getAccCodeLoading}
          onClick={handleForgotPassword}
          style={
            getAccCodeLoading
              ? {
                  cursor: "not-allowed",
                }
              : {}
          }
        >
          <RowFlex crossAxisAlignment="center" gap="10px">
            {/* {getAccCodeLoading && <Spinner direction="horizontal" />} */}
            <span className="color-text-blue-gradient">Send Reset Link</span>
          </RowFlex>
        </Fab>
      </ColumnFlex>
    </div>
  );
};

export default ForgotPassword;
