import {
  useGetAppointedKeyCollectionDateLazyQuery,
  useGetKeyCollectionAvailableDateLazyQuery,
  useGetKeyCollectionAvailableTimeLazyQuery,
  useUpdateKeyCollectionDateMutation,
} from "../../../../generated/graphql";
import SimpleDialog from "../../../../components/Dialog/SimpleDialog";
import { ColumnFlex } from "../../../../components/LayoutX/Flex/ColumnFlex";
import { useEffect, useState } from "react";
import TextX from "../../../../components/general/TextX";
import { RowFlex } from "../../../../components/LayoutX/Flex/RowFlex";
import { Button } from "ui-neumorphism";
import { Autocomplete, TextField } from "@mui/material";
import { useSnackbar } from "react-simple-snackbar";
import moment from "moment";
import { cardChangeColor } from "../../../context/CardContext";

type KeyCollectionDialogProps = {
  unitId: string;
  saleId: string;
  projectId: string;
  visible: boolean;
  onClose: () => void;
};

const KeyCollectionDialog: React.FC<KeyCollectionDialogProps> = ({
  unitId,
  saleId,
  projectId,
  visible,
  onClose,
}) => {
  const [openSnackbar] = useSnackbar();
  const { cardColor } = cardChangeColor();

  const [appointedDate, setAppointedDate] = useState<string>(null);
  const [selectedAvailableDate, setSelectedAvailableDate] =
    useState<string>(null);
  const [selectedAvailableTime, setSelectedAvailableTime] =
    useState<string>(null);

  const [isValidDate, setIsValidDate] = useState<boolean>(true);
  const [isValidTime, setIsValidTime] = useState<boolean>(true);

  const [getAppointedDate, { data: appointedDateResult }] =
    useGetAppointedKeyCollectionDateLazyQuery({
      onCompleted: (data) => {
        const formatDate = moment(data?.getAppointedKeyCollectionDate).format(
          "dddd, MMMM Do YYYY, h:mm:ss a"
        );
        setAppointedDate(formatDate);
      },
    });
  const [getAvailableDate, { data: availableDate }] =
    useGetKeyCollectionAvailableDateLazyQuery();
  const [getAvailableTime, { data: availableTimeList }] =
    useGetKeyCollectionAvailableTimeLazyQuery({});
  const [updateKeyCollectionDate] = useUpdateKeyCollectionDateMutation({
    onCompleted: (data) => {
      if (data?.updateKeyCollectionDate) {
        openSnackbar("Key collection updated successfully");
        onClose();
      }
    },
  });

  useEffect(() => {
    getAppointedDate({
      variables: {
        projectId,
        unitId,
      },
    });

    getAvailableDate({
      variables: {
        projectId,
        saleId,
        unitId,
      },
    });
  }, []);

  useEffect(() => {
    if (!selectedAvailableDate) return;
    getAvailableTime({
      variables: {
        projectId,
        saleId,
        unitId,
        collectionDate: selectedAvailableDate,
      },
    });
  }, [selectedAvailableDate]);

  const handleChangeDate = (value) => {
    if (!value) {
      setSelectedAvailableTime(null);
      setIsValidDate(false);
    } else {
      setSelectedAvailableTime(null);

      setIsValidDate(true);
    }

    setSelectedAvailableDate(value);
  };

  const handleChangeTime = (value) => {
    if (!value) {
      setIsValidTime(false);
    } else {
      setIsValidTime(true);
    }

    setSelectedAvailableTime(value);
  };

  const concatenateDateTime = () => {
    let date = moment(selectedAvailableDate).format("LL");
    let time = moment(selectedAvailableTime, ["h:mm A"]).format("HH:mm:ss");

    return new Date(moment(date + " " + time).format());
  };

  const handleConfirm = () => {
    if (!selectedAvailableDate) {
      setIsValidDate(false);
    }

    if (!selectedAvailableTime) {
      setIsValidTime(false);
    }

    if (!selectedAvailableDate || !selectedAvailableTime) {
      openSnackbar("Please fill in all required fields.");
      return;
    }

    const concatDateTime = concatenateDateTime();

    updateKeyCollectionDate({
      variables: {
        projectId,
        saleId,
        unitId,
        scheduledDateTime: concatDateTime,
      },
    });
  };

  return (
    <SimpleDialog visible={visible} onClose={null} background={cardColor}>
      <ColumnFlex gap="16px">
        <span className="fw-700 fs-16 color-text text-with-shadow">
          Key Collection Date
        </span>
        <span className="fs-12 color-text fw-500">
          Appointment Date:{" "}
          <span className="color-text-light-blue fw-700">
            {appointedDate || "-"}
          </span>
        </span>

        {/* Select Date */}
        <Autocomplete
          options={availableDate?.getKeyCollectionAvailableDate}
          value={selectedAvailableDate}
          onChange={(e, value) => handleChangeDate(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Date"
              required
              error={!isValidDate}
              helperText={!isValidDate ? `Date is required` : ""}
            />
          )}
        />

        {/* Select Time */}
        <Autocomplete
          options={availableTimeList?.getKeyCollectionAvailableTime}
          value={selectedAvailableTime}
          onChange={(e, value) => handleChangeTime(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Time Slot"
              required
              error={!isValidTime}
              helperText={!isValidTime ? `Time is required` : ""}
            />
          )}
        />

        <RowFlex
          gap="14px"
          fullWidth
          mainAxisAlignment="flex-end"
          className="disable-full-uppercase"
        >
          <Button onClick={onClose}>Cancel</Button>
          <Button className={"color-text-light-blue"} onClick={handleConfirm}>
            Confirm
          </Button>
        </RowFlex>
      </ColumnFlex>
    </SimpleDialog>
  );
};

export default KeyCollectionDialog;
