import React, { ReactNode } from "react";
import { Card as UINeumorphismCard } from "ui-neumorphism";

type CardNeuProps = {
  children: ReactNode;
  width?: string;
  height?: string;
  elavation?: number;
  dark?: boolean;
  inset?: boolean;
  bordered?: boolean;
  outlined?: boolean;
  flat?: boolean;
  rounded?: boolean;
  disabled?: boolean;
  loading?: boolean;
  style?: React.CSSProperties;
  className?: string;
  margin?: {
    all?: string;
    symmetric?: {
      vertical?: string;
      horizontal?: string;
    };
    only?: {
      top?: string;
      right?: string;
      bottom?: string;
      left?: string;
    };
  };
  padding?: {
    all?: string;
    symmetric?: {
      vertical?: string;
      horizontal?: string;
    };
    only?: {
      top?: string;
      right?: string;
      bottom?: string;
      left?: string;
    };
  };
  [key: string]: any;
};

const CardNeu = ({
  children,
  width,
  height,
  elavation = 2,
  dark = false,
  style,
  className,
  margin,
  padding,
  rounded,
  ...props
}: CardNeuProps) => {
  let marginConfig;
  if (margin) {
    if (margin.all) {
      marginConfig = margin.all;
    }
    if (margin.symmetric) {
      marginConfig = `${margin.symmetric?.vertical ?? "0px"} ${
        margin.symmetric?.horizontal ?? "0px"
      }`;
    }
    if (margin.only) {
      marginConfig = `${margin.only.top ?? "0px"} ${
        margin.only.right ?? "0px"
      } ${margin.only.bottom ?? "0px"} ${margin.only.left ?? "0px"}`;
    }
  }

  let paddingConfig;
  if (padding) {
    if (padding.all) {
      paddingConfig = padding.all;
    }
    if (padding.symmetric) {
      paddingConfig = `${padding.symmetric?.vertical ?? "0px"} ${
        padding.symmetric?.horizontal ?? "0px"
      }`;
    }
    if (padding.only) {
      paddingConfig = `${padding.only.top ?? "0px"} ${
        padding.only.right ?? "0px"
      } ${padding.only.bottom ?? "0px"} ${padding.only.left ?? "0px"}`;
    }
  }

  return (
    <UINeumorphismCard
      elevation={elavation}
      dark={dark}
      // style={{ width: width, height: height,  }}
      style={{
        ...style,
        width: width || style?.width,
        height: height || style?.height,
        padding: paddingConfig || style?.padding,
        margin: marginConfig || style?.margin,
        boxSizing: "border-box",
      }}
      rounded={rounded}
      className={className}
    >
      {children}
    </UINeumorphismCard>
  );
};

export default CardNeu;
